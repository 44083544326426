<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
          <h3 class="text-decoration-underline">Migration Manager (Only for TMC Support)</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-card-actions>
            <v-col>
              <v-btn
                id="devSettingSaveBtn"
                class="dev-setting-save-btn"
                color="primary"
                :disabled="true"
                @click="dataMigration"
              >
                Migrate Cancelled Warrant Data
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                id="devSettingSaveBtn"
                class="dev-setting-save-btn"
                color="primary"
                :disabled="true"
                @click="dataRollback"
              >
                Rollback Cancelled Warrant Data
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data: () => ({
    overlay: false,
  }),
  computed: {
  },
  created() {
  },
  methods: {
    ...mapActions('MigrationManager', ['migrateData']),
    async dataMigration() {
      this.overlay = true;
      await this.migrateData({
        migrationName: '2023.05.31.1-war-cancelled-amount',
        direction: 'up',
      });
      this.overlay = false;
    },
    async dataRollback() {
      this.overlay = true;
      await this.migrateData({
        migrationName: '2023.05.31.1-war-cancelled-amount',
        direction: 'down',
      });
      this.overlay = false;
    },
  },
};
</script>
